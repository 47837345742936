import { Component } from 'react';
//@ts-ignore
import logo from '../assets/logo.svg'
import { Widget, addResponseMessage, addTranscriptionMessage, toggleMsgLoader, addLinkSnippet } from '../index';
import { addUserMessage } from '..';
import React from 'react';
import socket from '../src/socket/socket';
//@ts-ignore
import profileAvatar from "../assets/avatar.png"

export default class App extends Component {
  componentDidMount() {
    // addResponseMessage("Здравствуйте! Что вас интересует?")

    socket.subscribe(e=>{
      console.log(e.content, e.type, e.content && e.type == "message")
      if(e.content && e.type == "message") addResponseMessage(e.content)
      if (e.content && e.type == "transcription") addTranscriptionMessage({
        text: e.content,
        final: e.final
      })
      if(e.type === 'history' && e.messages && Array.isArray(e.messages)){
        e.messages.forEach(message=>{
          if(message.content){
            if(message.side == 2){
              addUserMessage(message.content, message.datetime)
            }else{
              addResponseMessage(message.content, message.datetime) 
            }
          }
        })
      }
    });
    // addLinkSnippet({ link: 'https://google.com', title: 'Google' });
    
  }

  handleNewUserMessage = (newMessage: any) => {
    toggleMsgLoader();
  }

  handleQuickButtonClicked = (e: any) => {
    addResponseMessage('Selected ' + e);
  }

  handleSubmit = (msgText: string) => {
    
    return true;
  }

  render() {
    return (
      //@ts-ignore
      <Widget
        profileAvatar={profileAvatar}
        subtitle="Chat"
        senderPlaceHolder="Напишите сообщение..."
        handleNewUserMessage={this.handleNewUserMessage}
        handleQuickButtonClicked={this.handleQuickButtonClicked}
        imagePreview
        handleSubmit={this.handleSubmit}
        emojis
      />
    );
  }
}
